import { createError, navigateTo } from "#imports";
import {
  useInfiniteQuery as _useInfiniteQuery,
  keepPreviousData,
  useMutation,
  useQuery,
  type MutationFunction,
  type MutationKey,
  type QueryClient,
  type QueryFunction,
  type QueryKey,
  type UseQueryReturnType,
} from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import useNotify from "~/composables/useNotify";
import { getErrorMessagesFromError } from "~/utils/helpers";

import type { $Fetch } from "nitropack";
import type { Notifier } from "~/composables/useNotify";
import type {
  APIPagedResponse,
  DefaultListBody,
  RequestObject,
} from "~/src/models/utils/Api.model";
import type {
  DeleteRes,
  DeleteRoutes,
  GetRes,
  GetRoutes,
  PagedRoutes,
  PostRes,
  PostRoutes,
  PutRes,
  PutRoutes,
} from "~/types/api";
import type { Prettify } from "~/utils/helpers";
import type { ExpandValue } from "~/utils/uriTemplates";
import type { useOurNuxtApp } from "~/utils/nuxt";

const { notifySuccess, notifyError } = useNotify();

export type MyQueryOptions<Sus extends boolean = true> = {
  keepPreviousData?: boolean;
  createNuxtError?: boolean;
  /**
   * This will make the query only fetch once on first time used.
   * Essentially cached for the remainder of the session **/
  staticData?: boolean;
  suspense?: Sus;
  enabled?: MaybeRef<boolean>;
};

// Query
const defaultQueryOptions: MyQueryOptions = {
  createNuxtError: true,
  keepPreviousData: false,
  staticData: false,
  suspense: true,
  enabled: true,
};

export const createQuery = <T, Sus extends boolean = true>(
  queryKey: MaybeRef<QueryKey>,
  queryFn: QueryFunction<T>,
  options?: MyQueryOptions<Sus>
): Sus extends true
  ? Promise<UseQueryReturnType<T, Error>>
  : UseQueryReturnType<T, Error> => {
  const _options = { ...defaultQueryOptions, ...options };
  const staleTime = _options.staticData ? Infinity : 1000 * 60 * 3; // 3 mins

  const q = useQuery({
    queryKey,
    queryFn: queryFn,
    staleTime,
    throwOnError: false,
    refetchOnWindowFocus: false,
    retry: false,
    suspense: true,
    enabled: _options.enabled,
    placeholderData: _options.keepPreviousData ? keepPreviousData : undefined,
  });

  if (_options.suspense && unref(_options.enabled)) {
    return q
      .suspense()
      .then(() => q)
      .catch((e) => {
        if (_options.createNuxtError) throw createError(e as string);
        throw e;
      }) as any;
  }

  return q as any;
};

// Mutate
type MutateOptions<T = any, K = any> = {
  showError?: boolean;
  showSuccess?: boolean;
  resetOnError?: boolean;
  invalidateOnSuccess?: boolean; // on settled?
  mutationKey?: MaybeRef<MutationKey>;
  onSuccess?: (x: T, y: K) => void;
  onError?: (x: Error, y: K) => void;
};

const defaultMutauteOptions: MutateOptions = {
  showError: false,
  showSuccess: false,
  resetOnError: false,
  invalidateOnSuccess: false,
  mutationKey: undefined,
};

export const createMutation = <TData, TVariables>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: MutateOptions<TData, TVariables>
) => {
  const _options = { ...defaultMutauteOptions, ...options };
  const m = useMutation({
    mutationKey: _options.mutationKey,
    mutationFn: mutationFn,
    onError: (error, variables) => {
      if (_options.showError)
        notifyError(getErrorMessagesFromError(error).join("\n"));
      if (_options.resetOnError) m.reset();
      if (_options.onError) _options.onError(error, variables);
    },
    onSuccess: (data, variables) => {
      if (_options.showSuccess) notifySuccess();
      if (_options.onSuccess) _options.onSuccess(data, variables);
    },
  });
  return m;
};

type IdTemplate<T extends string> = {
  expand(arg: { id: ExpandValue }): T;
};

type TOptions<TApiResponse, TResponse, TBody = any> = {
  notifier?: Notifier;
  entityName?: string;
  navigateTo?: string;
  deserialise?: (res: TApiResponse) => TResponse;
  body?: (body: TBody) => Record<string, any>;
};

export function useSimpleGetQuery<
  KEntity extends string,
  Route extends GetRoutes,
  TResponse = GetRes<Route>,
>(
  entityName: KEntity,
  $api: $Fetch,
  queryKey: string,
  endpoint: IdTemplate<Route>,
  options?: { deserialise: (res: GetRes<Route>) => TResponse }
) {
  type TFetchRes = GetRes<Route>;
  const get: (id: string, signal?: AbortSignal) => Promise<TResponse> =
    options?.deserialise
      ? (id, signal) =>
          $api<TFetchRes>(endpoint.expand({ id }), {
            signal,
          }).then(options?.deserialise)
      : (id, signal) =>
          $api<TFetchRes>(endpoint.expand({ id }), {
            signal,
          }) as Promise<TResponse>;
  const useGetQuery = async (id: string, createNuxtError = true) =>
    createQuery([queryKey, id], ({ signal }) => get(id, signal), {
      createNuxtError,
    });

  type Ret = Prettify<
    {
      [key in `get${KEntity}s`]: typeof get;
    } & {
      [key in `useGet${KEntity}s`]: typeof useGetQuery;
    }
  >;
  return {
    [`get${entityName}s`]: get,
    [`useGet${entityName}s`]: useGetQuery,
  } as Ret;
}

type I18n = ReturnType<typeof useOurNuxtApp>["$i18n"];

export class DefaultServiceBuilder<KEntity extends string> {
  constructor(
    public entityName: KEntity,
    public $api: $Fetch,
    public queryKeys: { list: string; get: string },
    public client?: QueryClient,
    public notifier?: Notifier,
    public t?: I18n["t"]
  ) {}

  useDefaultGetQuery<Route extends GetRoutes, TResponse = GetRes<Route>>(
    endpoint: IdTemplate<Route>,
    options?: {
      deserialise: (res: GetRes<Route>) => TResponse;
    }
  ) {
    type TFetchRes = GetRes<Route>;
    const get: (id: string, signal?: AbortSignal) => Promise<TResponse> =
      options?.deserialise
        ? (id, signal) =>
            this.$api<TFetchRes>(endpoint.expand({ id }), {
              signal,
            }).then(options?.deserialise)
        : (id, signal) =>
            this.$api<TFetchRes>(endpoint.expand({ id }), {
              signal,
            }) as Promise<TResponse>;
    const useGetQuery = async <Sus extends boolean = true>(
      id: MaybeRef<string | null>,
      options?: MyQueryOptions<Sus>
    ) =>
      createQuery<TResponse | null, Sus>(
        [this.queryKeys.get, id],
        ({ signal }) => {
          const _id = unref(id);
          if (!_id) return null;
          return get(_id, signal);
        },
        options
      );

    type Ret = Prettify<
      {
        [key in `get${KEntity}ById`]: typeof get;
      } & {
        [key in `useGet${KEntity}ByIdQuery`]: typeof useGetQuery;
      }
    >;
    return {
      [`get${this.entityName}ById`]: get,
      [`useGet${this.entityName}ByIdQuery`]: useGetQuery,
    } as Ret;
  }

  useDefaultListQuery<
    Route extends PagedRoutes,
    TResponse extends APIPagedResponse<any> = PostRes<Route>,
  >(
    endpoint: Route,
    options?: { deserialise: (res: PostRes<Route>) => TResponse }
  ) {
    type TFetchRes = PostRes<Route>;
    const fetchList: (
      body: DefaultListBody,
      signal?: AbortSignal
    ) => Promise<TResponse> = options?.deserialise
      ? (body, signal) =>
          this.$api<TFetchRes>(endpoint, {
            method: "POST",
            body,
            signal,
          }).then(options.deserialise)
      : (body, signal) =>
          this.$api<TFetchRes>(endpoint, {
            method: "POST",
            body,
            signal,
          }) as Promise<TResponse>;
    const useListQuery = (
      body: MaybeRef<DefaultListBody>,
      createNuxtError = true
    ) =>
      createQuery(
        [this.queryKeys.list, body],
        ({ signal }) => fetchList(unref(body), signal),
        {
          createNuxtError,
        }
      );
    const useInfiniteQuery = (
      pageSize: number,
      req: MaybeRef<Prettify<Omit<RequestObject, "page" | "pageSize">>>
    ) =>
      _useInfiniteQuery({
        queryKey: [this.queryKeys.list, req],
        initialPageParam: 1,
        queryFn: ({ pageParam, signal }) =>
          fetchList({ pageSize, page: pageParam, ...unref(req) }, signal),
        getNextPageParam: (e) => {
          const nextPageIndex = e.page.number + 1;
          const hasNextPage = e.page.totalElements > nextPageIndex * pageSize;
          return hasNextPage ? nextPageIndex : null;
        },
        suspense: true,
        placeholderData: keepPreviousData,
      });

    type Ret = Prettify<
      {
        [key in `list${KEntity}s`]: typeof fetchList;
      } & {
        [key in `useList${KEntity}sQuery`]: typeof useListQuery;
      } & {
        [key in `useInfinite${KEntity}sQuery`]: typeof useInfiniteQuery;
      }
    >;
    return {
      [`list${this.entityName}s`]: fetchList,
      [`useList${this.entityName}sQuery`]: useListQuery,
      [`useInfinite${this.entityName}sQuery`]: useInfiniteQuery,
    } as Ret;
  }

  // TODO - onSuccess/onError not called
  useDefaultUpdateMutation<
    Route extends PutRoutes,
    TBody,
    TResponse = PutRes<Route>,
  >(
    endpoint: IdTemplate<Route>,
    options?: TOptions<PutRes<Route>, TResponse, TBody>
  ) {
    const queryClient = this.client;
    if (!queryClient)
      throw new Error("must provide query client for update queries");

    type TFetchRes = PutRes<Route>;
    const update = async (id: string, payload: TBody) => {
      const body: any = options?.body?.(payload) ?? null;
      const res = await this.$api<TFetchRes>(endpoint.expand({ id }), {
        method: "PUT",
        body,
      });
      if (options?.deserialise) return options.deserialise(res);
      return res as TResponse;
    };

    const useUpdateMutation = () => {
      type MutationBody = {
        id: string;
        value: TBody;
      };
      return createMutation(
        ({ id, value }: MutationBody) => update(id, value),
        {
          onSuccess: (x, { id }) => {
            queryClient.invalidateQueries({
              queryKey: [this.queryKeys.get, id],
            });
            queryClient.invalidateQueries({
              queryKey: [this.queryKeys.list],
            });
            options?.notifier?.notifySuccess(
              "Success",
              `${this.entityName.toLocaleUpperCase()} updated`
            );
            if (options?.navigateTo) navigateTo(options.navigateTo);
          },
          onError: (error) => {
            options?.notifier?.notifyError(
              error,
              `Error updating ${this.entityName}`
            );
          },
        }
      );
    };

    type Ret = Prettify<
      {
        [key in `update${KEntity}`]: typeof update;
      } & {
        [key in `useUpdate${KEntity}Mutation`]: typeof useUpdateMutation;
      }
    >;
    return {
      [`update${this.entityName}`]: update,
      [`useUpdate${this.entityName}Mutation`]: useUpdateMutation,
    } as Ret;
  }

  // TODO - onSuccess/onError not called
  useDefaultDeleteMutation<
    Route extends DeleteRoutes,
    TResponse = DeleteRes<Route>,
  >(
    endpoint: IdTemplate<Route>,
    options?: TOptions<DeleteRes<Route>, TResponse>
  ) {
    const queryClient = this.client;
    if (!queryClient)
      throw new Error("must provide query client for update queries");

    type TFetchRes = DeleteRes<Route>;
    const deleteRequest = async (id: string) => {
      const res = await this.$api<TFetchRes>(endpoint.expand({ id }), {
        method: "DELETE",
      });
      if (options?.deserialise) return options.deserialise(res);
      return res as TResponse;
    };

    const useDeleteMutation = () => {
      return createMutation((id: string) => deleteRequest(id), {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [this.queryKeys.list],
          });
          options?.notifier?.notifySuccess(
            "Success",
            `${this.entityName.toLocaleUpperCase()} deleted`
          );
          if (options?.navigateTo) navigateTo(options.navigateTo);
        },
        onError: (error) => {
          options?.notifier?.notifyError(
            error,
            `Error deleting ${this.entityName}`
          );
        },
      });
    };

    type Ret = Prettify<
      {
        [key in `delete${KEntity}`]: typeof deleteRequest;
      } & {
        [key in `useDelete${KEntity}Mutation`]: typeof useDeleteMutation;
      }
    >;
    return {
      [`delete${this.entityName}`]: deleteRequest,
      [`useDelete${this.entityName}Mutation`]: useDeleteMutation,
    } as Ret;
  }

  useDefaultCreateMutation<
    Route extends PostRoutes,
    TBody,
    TResponse = PostRes<Route>,
  >(endpoint: Route, options?: TOptions<PostRes<Route>, TResponse, TBody>) {
    const queryClient = this.client;
    if (!queryClient)
      throw new Error("must provide query client for update queries");

    type TFetchRes = PostRes<Route>;
    const create = async (payload: TBody) => {
      const body: any = options?.body?.(payload) ?? null;
      const res = await this.$api<TFetchRes>(endpoint, {
        method: "POST",
        body,
      });
      if (options?.deserialise) return options.deserialise(res);
      return res as TResponse;
    };

    const useCreateMutation = () => {
      return createMutation((body: TBody) => create(body), {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [this.queryKeys.list],
          });
          options?.notifier?.notifySuccess(
            this.t?.("success.success"),
            `${this.entityName.toLocaleUpperCase()} created`
          );
          if (options?.navigateTo) navigateTo(options.navigateTo);
        },
        onError: (error) => {
          options?.notifier?.notifyError(
            error,
            `Error creating ${this.entityName}`
          );
        },
      });
    };

    type Ret = Prettify<
      {
        [key in `create${KEntity}`]: typeof create;
      } & {
        [key in `useCreate${KEntity}Mutation`]: typeof useCreateMutation;
      }
    >;
    return {
      [`create${this.entityName}`]: create,
      [`useCreate${this.entityName}Mutation`]: useCreateMutation,
    } as Ret;
  }
}
export const invalidateQueries = (queries: string[][], client: QueryClient) => {
  for (const query of queries) {
    client.invalidateQueries({
      queryKey: query,
    });
  }
};
