<template>
  <div
    class="remove-border--left remove-border--y add-border layout-wrapper-bg"
  >
    <div class="column full-height justify-between no-wrap">
      <div class="relative-position">
        <NuxtLink to="/" class="column justify-center" style="height: 50px">
          <q-img
            :src="`/images/brand/haz_h${dark ? '_dark' : ''}.svg`"
            alt="hazcheckLogo"
            data-cy="secondaryLogo"
            eager
            fit="contain"
            height="25px"
            width="100%"
          />
        </NuxtLink>
        <q-separator />
        <suspense>
          <LayoutRail />
        </suspense>
      </div>
      <div class="column full-width q-pt-md">
        <div class="column full-width items-center q-pb-md">
          <template v-if="store.isLoggedIn">
            <ClientOnly>
              <RealTimeStatus class="q-mb-xs" />
              <template #fallback>
                <div class="q-mb-sm">
                  <q-skeleton type="QChip" style="height: unset; width: 76px" />
                </div>
              </template>
            </ClientOnly>
          </template>
          <div class="full-width text-center q-mb-lg">
            <q-separator class="full-width" />
            <div class="text-subtitle2" style="font-size: 12px">
              {{ config.public.version }}
              
            </div>
            <q-separator class="full-width" />
          </div>
          <Logo
            v-if="store.showWsc"
            variant="wsc"
            fit="contain"
            height="35px"
            class="q-px-sm"
          />
          <Logo
            variant="ncb-square"
            height="40px"
            fit="contain"
            class="q-mb-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { NuxtLink, LayoutRail, Logo, RealTimeStatus } from "#components";
import { useRuntimeConfig, useUserStore } from "#imports";
import { useDarkMode } from "~/composables/useDarkMode";

defineProps<{
  leftDrawerOpen: boolean;
}>();
defineEmits(["click:toggle-drawer"]);

const store = useUserStore();
const config = useRuntimeConfig();
const dark = useDarkMode();
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>
