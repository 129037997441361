import { useQueryClient } from "@tanstack/vue-query";
import type { InternalApi } from "nitropack";
import { useOurNuxtApp } from "~/utils/nuxt";

import useNotify from "~/composables/useNotify";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  invalidateQueries,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import type { MaybeRef } from "vue";
import { unref } from "vue";
import { InvalidationKeys } from "~/utils/queryInvalidators";

export const useConfigurationService = () => {
  const {
    $i18n: { t },
    $api,
  } = useOurNuxtApp();
  const { notifySuccess, notifyError } = useNotify();
  const queryClient = useQueryClient();

  // TODO - move risks from here?
  const getRisks = (signal?: AbortSignal) =>
    $api("/api/cm/config/risks", { signal });

  const useRisksQuery = (createNuxtError = true) => {
    return createQuery(
      [QUERY_KEYS.Risks.get],
      ({ signal }) => getRisks(signal),
      { createNuxtError, staticData: true }
    );
  };

  const getCaseColumnsConfig = (signal?: AbortSignal) => {
    return $api("/api/cm/config/case-table-columns", { signal });
  };

  const useCaseColumnsConfigQuery = (createNuxtError = true) => {
    return createQuery(
      [QUERY_KEYS.CaseColumnsConfig.get],
      ({ signal }) => getCaseColumnsConfig(signal),
      { createNuxtError, staticData: true }
    );
  };

  const getCasePageConfig = (signal?: AbortSignal) =>
    $api("/api/cm/config/case-page", { signal });

  const useCasePageConfigQuery = (createNuxtError = true) => {
    return createQuery(
      [QUERY_KEYS.CasePageConfig.get],
      ({ signal }) => getCasePageConfig(signal),
      { createNuxtError, staticData: true }
    );
  };

  // This metadata is what the user needs to supply to change the status of a case
  const getStatusMetadataConfig = async (signal?: AbortSignal) =>
    $api("/api/cm/config/status-metadata", { signal });

  const useStatusMetadataQuery = <Sus extends boolean = true>(
    options?: MyQueryOptions<Sus>
  ) => {
    return createQuery(
      [QUERY_KEYS.StatusMetadata.get],
      ({ signal }) => getStatusMetadataConfig(signal),
      { ...options, staticData: true }
    );
  };

  const getCaseTableFilterConfig = (signal?: AbortSignal) => {
    return $api("/api/cm/config/case-filters", { signal });
  };

  const getCaseTableFilterConfigCached = () => {
    return queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.CaseTableFilters.get],
      retry: false,
      staleTime: Infinity,
      queryFn: ({ signal }) => getCaseTableFilterConfig(signal),
    });
  };

  const useCaseTableFilterConfigQuery = (createNuxtError = true) => {
    return createQuery(
      [QUERY_KEYS.CaseTableFilters.get],
      ({ signal }) => getCaseTableFilterConfig(signal),
      { createNuxtError, staticData: true }
    );
  };

  const getConfigJson = async (id: string, signal?: AbortSignal) =>
    $api<InternalApi["/api/cm/config/raw/:configurationId"]["get"]>(
      "/api/cm/config/raw/" + id,
      { signal }
    );

  const useConfigJsonQuery = async (id: string, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Config.get, id],
      ({ signal }) => getConfigJson(id, signal),
      { createNuxtError, staticData: true }
    );

  const updateConfigJson = async (
    id: string,
    body: string,
    signal?: AbortSignal
  ) =>
    await $api<InternalApi["/api/cm/config/raw/:configurationId"]["put"]>(
      "/api/cm/config/raw/" + id,
      {
        method: "PUT",
        body: {
          json: body,
        },
        signal,
      }
    );

  const validateCaseListConfig = async (body: string, signal?: AbortSignal) =>
    await $api("/api/cm/config/raw/case-list-validation", {
      method: "POST",
      body: {
        json: body,
      },
      signal,
    });

  const useValidateCaseListConfigQuery = (
    body: MaybeRef<string>,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.Config.caseListValidate, body],
      ({ signal }) => validateCaseListConfig(unref(body), signal),
      options
    );

  const updateConfigJsonMutation = (options?: {
    onError?: (e: unknown) => any;
    onSuccess?: () => any;
  }) =>
    createMutation(
      ({ id, body }: { id: string; body: string }) =>
        updateConfigJson(id, body),
      {
        onSuccess: (_x, { id }) => {
          notifySuccess(t("success.configuration_updated"));
          // FORM
          invalidateQueries(
            InvalidationKeys.Configuration.update(id),
            queryClient
          );
        },
        onError: (error) =>
          options?.onError ? options.onError(error) : notifyError(error),
      }
    );

  const getDefaultConfig = async (id: string, signal?: AbortSignal) =>
    await $api<InternalApi["/api/cm/config/default/:configurationId"]["get"]>(
      `/api/cm/config/default/${id}`,
      { signal }
    );

  const useGetDefaultConfigQuery = (id: string, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Config.default.get, id],
      ({ signal }) => getDefaultConfig(id, signal),
      { createNuxtError, staticData: true }
    );

  return {
    getRisks,
    useRisksQuery,

    useCaseColumnsConfigQuery,
    getCaseColumnsConfig,

    useStatusMetadataQuery,
    getStatusMetadataConfig,

    useCaseTableFilterConfigQuery,
    getCaseTableFilterConfig,
    getCaseTableFilterConfigCached,

    useConfigJsonQuery,
    updateConfigJsonMutation,

    validateCaseListConfig,
    useValidateCaseListConfigQuery,

    useGetDefaultConfigQuery,
    useCasePageConfigQuery,
  };
};
