import Prando from "prando";

import type { AnomalyViewModel } from "~/src/models/Case/Anomaly.model";
import type { DgPredictionViewModel } from "~/src/models/Case/DgPrediction.model";

export function getProbabilityTag(probability: number): string {
  if (probability < 0.25) return "case.issues.probability.unlikely";
  if (probability < 0.5) return "case.issues.probability.possible";
  if (probability < 0.75) return "case.issues.probability.likely";
  return "case.issues.probability.very_likely";
}

export function getMockDgPrediction(
  screeningId: string
): DgPredictionViewModel {
  const generator = new Prando(screeningId);
  const index = generator.nextInt(0, exampleDgPredictions.length - 1);
  return exampleDgPredictions[index];
}

export function getMockAnomaly(screeningId: string): AnomalyViewModel {
  const generator = new Prando(screeningId);
  const index = generator.nextInt(0, exampleAnomaly.length - 1);
  return exampleAnomaly[index];
}

export enum Probability {
  Unlikely,
  Possible,
  Likely,
  VeryLikely,
}

export function groupProbability(probability: number): Probability {
  if (probability < 0.25) return Probability.Unlikely;
  if (probability < 0.5) return Probability.Possible;
  if (probability < 0.75) return Probability.Likely;
  return Probability.VeryLikely;
}

const exampleDgPredictions: DgPredictionViewModel[] = [
  {
    reference: "64632624",
    screeningId: "2024-09-09-0_031dcc96-94cd-42a4-8019-47fccf0a6c19",
    prediction: true,
    probability: 0.51221365,
    booking: {
      prediction: true,
      probability: 0.51221365,
      descriptions: [
        {
          source: "customerReference",
          path: "$.customerReference",
          prediction: false,
          probability: 0.020659793,
        },
        {
          source: "internalReference",
          path: "$.internalReference",
          prediction: true,
          probability: 0.51221365,
        },
      ],
    },
    cargoTransportUnits: [
      {
        ctuId: "0",
        cargo: [
          {
            cargoId: "1",
            path: "$.cargo-transport-units[0].cargo[0]",
            declaredDg: false,
            prediction: true,
            probability: 0.95271045,
            descriptions: [
              {
                source: "Cargo Short Description",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Cargo Short Description')]",
                prediction: true,
                probability: 0.1068153,
              },
              {
                source: "Harmonized Code",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Harmonized Code')]",
                prediction: true,
                probability: 0.6743267,
              },
              {
                source: "MTD",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'MTD')]",
                prediction: true,
                probability: 0.9993899,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    reference: "EBKG09317709",
    screeningId: "2024-07-02-0_0a75b42d-dab7-46b8-a611-d410ef792390",
    prediction: true,
    probability: 0.51221365,
    booking: {
      prediction: true,
      probability: 0.51221365,
      descriptions: [
        {
          source: "customerReference",
          path: "$.customerReference",
          prediction: false,
          probability: 0.020659793,
        },
        {
          source: "internalReference",
          path: "$.internalReference",
          prediction: true,
          probability: 0.51221365,
        },
      ],
    },
    cargoTransportUnits: [
      {
        ctuId: "0",
        cargo: [
          {
            cargoId: "0",
            path: "$.cargo-transport-units[0].cargo[0]",
            declaredDg: false,
            prediction: true,
            probability: 0.6802658484609465,
            descriptions: [
              {
                source: "Harmonized Code",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Harmonized Code')]",
                prediction: true,
                probability: 0.5061535757257577,
              },
              {
                source: "CargoDescription",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'CargoDescription')]",
                prediction: true,
                probability: 0.8153826273104214,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    reference: "65629289",
    screeningId: "2024-08-07-0_0b137ace-cd2c-480e-bfae-7797de1913aa",
    prediction: true,
    probability: 0.51221365,
    booking: {
      prediction: true,
      probability: 0.51221365,
      descriptions: [
        {
          source: "customerReference",
          path: "$.customerReference",
          prediction: false,
          probability: 0.020659793,
        },
        {
          source: "internalReference",
          path: "$.internalReference",
          prediction: true,
          probability: 0.51221365,
        },
      ],
    },
    cargoTransportUnits: [
      {
        ctuId: "0",
        cargo: [
          {
            cargoId: "0",
            path: "$.cargo-transport-units[0].cargo[0]",
            declaredDg: false,
            prediction: true,
            probability: 0.7110684985758059,
            descriptions: [
              {
                source: "Cargo Short Description",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Cargo Short Description')]",
                prediction: true,
                probability: 0.9725903709959589,
              },
              {
                source: "Harmonized Code",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Harmonized Code')]",
                prediction: true,
                probability: 0.5664097195650846,
              },
              {
                source: "MTD",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'MTD')]",
                prediction: false,
                probability: 0.14580238719105684,
              },
              {
                source: "un-number",
                path: "$.cargo-transport-units[0].cargo[0].un-number",
                prediction: false,
                probability: 0.14580238719105684,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    reference: "36151897",
    screeningId: "2024-08-12-1_14f067b3-e4e1-4694-b9e0-716f93a9b317",
    prediction: true,
    probability: 0.51221365,
    booking: {
      prediction: true,
      probability: 0.51221365,
      descriptions: [
        {
          source: "customerReference",
          path: "$.customerReference",
          prediction: false,
          probability: 0.020659793,
        },
        {
          source: "internalReference",
          path: "$.internalReference",
          prediction: true,
          probability: 0.51221365,
        },
      ],
    },
    cargoTransportUnits: [
      {
        ctuId: "0",
        cargo: [
          {
            cargoId: "2",
            path: "$.cargo-transport-units[0].cargo[0]",
            declaredDg: false,
            prediction: true,
            probability: 0.9996274571998837,
            descriptions: [
              {
                source: "Cargo Short Description",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Cargo Short Description')]",
                prediction: true,
                probability: 0.992160062436049,
              },
              {
                source: "Harmonized Code",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'Harmonized Code')]",
                prediction: true,
                probability: 0.7748913373799414,
              },
              {
                source: "MTD",
                path: "$.cargo-transport-units[0].cargo[0].description[?(@.source == 'MTD')]",
                prediction: true,
                probability: 0.9999824232935498,
              },
            ],
          },
          {
            cargoId: "3",
            path: "$.cargo-transport-units[0].cargo[1]",
            declaredDg: false,
            prediction: true,
            probability: 0.9990197622743657,
            descriptions: [
              {
                source: "Cargo Short Description",
                path: "$.cargo-transport-units[0].cargo[1].description[?(@.source == 'Cargo Short Description')]",
                prediction: true,
                probability: 0.9841911981472735,
              },
              {
                source: "Harmonized Code",
                path: "$.cargo-transport-units[0].cargo[1].description[?(@.source == 'Harmonized Code')]",
                prediction: true,
                probability: 0.7748913373799414,
              },
              {
                source: "MTD",
                path: "$.cargo-transport-units[0].cargo[1].description[?(@.source == 'MTD')]",
                prediction: true,
                probability: 0.9999400665665622,
              },
            ],
          },
        ],
      },
    ],
  },
];

const exampleAnomaly: AnomalyViewModel[] = [
  {
    reference: "69311274",
    screeningId: "2024-10-22-7_7ca7f389-c084-4619-aa6b-f8578716ef9e",
    sailings: [
      {
        description: "common",
        origin: "USHOU",
        destination: "PECLL",
      },
      {
        description: "common",
        origin: "USHOU",
        destination: "None",
      },
      {
        description: "common",
        origin: "None",
        destination: "PECLL",
      },
    ],
    cargoTransportUnits: [
      {
        ctuId: "0",
        cargo: [
          {
            cargoId: "0",
            results: [
              {
                label: "Schema",
                value: "dangerous-cargo",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[0].Schema",
              },
              {
                label: "UN",
                value: "1993",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[0].un-number",
              },
              {
                label: "PSN",
                value: "FLAMMABLE LIQUID, N.O.S.",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[0].proper-shipping-name",
              },
            ],
          },
          {
            cargoId: "1",
            results: [
              {
                label: "Schema",
                value: "dangerous-cargo",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[1].Schema",
              },
              {
                label: "UN",
                value: "1993",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[1].un-number",
              },
              {
                label: "PSN",
                value: "FLAMMABLE LIQUID, N.O.S.",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[1].proper-shipping-name",
              },
            ],
          },
          {
            cargoId: "2",
            results: [
              {
                label: "Schema",
                value: "dangerous-cargo",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[2].Schema",
              },
              {
                label: "UN",
                value: "1993",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[2].un-number",
              },
              {
                label: "PSN",
                value: "FLAMMABLE LIQUID, N.O.S.",
                results: [
                  {
                    description: "rare",
                    origin: "USHOU",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "USHOU",
                    destination: "None",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "PECLL",
                  },
                  {
                    description: "common",
                    origin: "None",
                    destination: "None",
                  },
                ],
                path: "$.cargo-transport-units[0].cargo[2].proper-shipping-name",
              },
            ],
          },
        ],
      },
    ],
  },
];
