import { defineVueQueryPluginHook } from "#imports";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
export const pluginHook = defineVueQueryPluginHook(({ queryClient }) => {
  broadcastQueryClient({
    queryClient,
    broadcastChannel: "hcd-channel"
  });
  return {
    pluginReturn: {},
    vueQueryPluginOptions: { queryClient }
  };
});
