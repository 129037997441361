import { inject, provide, type Ref, ref } from "vue";
import {
  EntityType,
  getFriendlyLocationInfoFromField,
  parsePathFromMatch,
  type HitPath,
  type IdOrIndex,
} from "~/utils/hitHelpers";

import type { Description } from "~/src/models/Case/Booking.viewmodel";
import type { RouteLocationAsRelativeGeneric } from "#vue-router";

const JSON_PATH_ROOT = "$";

export class PathContextBuilder {
  public path: string[] = [JSON_PATH_ROOT];

  constructor(initial?: PathContextBuilder) {
    if (initial) {
      this.path = [...initial.path];
    }
  }

  provideContext() {
    provide("bookingPath", ref(this.path));
    return this;
  }

  fromContext() {
    const injectedValue = inject<Ref<string[]>>("bookingPath")?.value;
    if (!injectedValue) throw new Error("Path context not created");
    this.path = [...injectedValue];
    return this;
  }

  withProperty(path: string) {
    this.path.push(path);
    return this;
  }

  // inserts an index in the last path element if possible
  withIndex(index: number) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\d+\]/, "");
    this.path[this.path.length - 1] = `${base}[${index}]`;
    return this;
  }

  withKeyValueArray(key: string, source: string) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\?\s*]/, "");
    this.path[this.path.length - 1] = `${base}[?(@.${key} == '${source}')]`;
    return this;
  }

  build() {
    return this.path.join(".");
  }
}

export type Path = InstanceType<typeof PathContextBuilder>;

const ctuRegEx = /cargo-transport-units\[(\d+)\]/i;
export function parseCtuIndexFromField(field: string) {
  const match = field.match(ctuRegEx);
  if (match === null) return null;
  return Number(match[1]);
}

const cargoRegex = /cargo\[(\d+)\]/i;
export function parseCargoIndexFromField(field: string) {
  const match = field.match(cargoRegex);
  if (match === null) return null;
  return Number(match[1]);
}

export function parseCtuAndMaybeCargoIndexFromField(
  field: string
): [number, number | null] | null {
  const ctuIndex = parseCtuIndexFromField(field);
  if (ctuIndex === null) return null;
  const cargoIndex = parseCargoIndexFromField(field);
  return [ctuIndex, cargoIndex];
}

export function parsePartyIndexFromPath(path: string) {
  const match = partyRegex.exec(path);
  return match ? parseInt(match[1]) : null;
}
const partyRegex = /parties\[(\d+)\]/;

export function parseSailingIndexFromPath(path: string) {
  const match = sailingRegex.exec(path);
  if (match) {
    return parseInt(match[1]);
  }
  return null;
}
const sailingRegex = /sailings\[(\d+)\]/;

export function parseStageIndexFromPath(path: string) {
  const match = stageRegex.exec(path);
  if (match) {
    return parseInt(match[1]);
  }
  return null;
}
const stageRegex = /stages\[(\d+)\]/;

export function parseSailingAndMaybeStageIndexFromField(
  field: string
): [number, number | null] | null {
  const sailingIndex = parseSailingIndexFromPath(field);
  if (sailingIndex === null) return null;
  const stageIndex = parseStageIndexFromPath(field);
  return [sailingIndex, stageIndex];
}

/** Can be accessed by index or by $id or source */
export function getPathsForDescription(
  path: Path,
  desc: Description,
  index: number,
  text: boolean = true
): Set<string> {
  const allPossiblePaths = new Set<string>();

  allPossiblePaths.add(
    new PathContextBuilder(path).withIndex(index).withProperty("text").build()
  );

  if (desc.$id) {
    let builder = new PathContextBuilder(path).withKeyValueArray(
      "$id",
      desc.$id
    );
    if (text) builder = builder.withProperty("text");
    allPossiblePaths.add(builder.build());
  }
  if (desc.source) {
    let builder = new PathContextBuilder(path).withKeyValueArray(
      "source",
      desc.source
    );
    if (text) builder = builder.withProperty("text");
    allPossiblePaths.add(builder.build());
  }

  return allPossiblePaths;
}

export function parseCtuIndexFromPath(field: string) {
  const match = field.match(ctuPathRegEx);
  if (match === null) return null;
  return Number(match[1]);
}
const ctuPathRegEx = /containers\[(\*+)\]/i;

export function parseCargoIndexFromPath(field: string) {
  const match = field.match(cargoPathRegex);
  if (match === null) return null;
  return Number(match[1]);
}
const cargoPathRegex = /cargo\[(\*+)\]/i;

export function parseCtuAndMaybeCargoIndexFromPath(
  field: string
): [number, number | null] | null {
  const ctuIndex = parseCtuIndexFromPath(field);
  if (ctuIndex === null) return null;
  const cargoIndex = parseCargoIndexFromPath(field);
  return [ctuIndex, cargoIndex];
}

export function getFirstMatch(field: string, regex: RegExp): string | null {
  const matches = field.matchAll(regex);
  for (const match of matches) {
    return match[1];
  }
  return null;
}

type HitLink = {
  name: `cases-caseId-hits-${HitPath}`;
  params: {
    caseId: string;
  };
};
type ContainerLink = {
  name: "cases-caseId-hits-containers-ctuId";
  params: {
    caseId: string;
    ctuId: number;
  };
};
type CargoLink = {
  name: "cases-caseId-hits-containers-ctuId-cargo-cargoId";
  params: {
    caseId: string;
    ctuId: number;
    cargoId: number;
  };
};
type SailingPathLink = {
  name: "cases-caseId-hits-sailings";
  params: {
    caseId: string;
  };
};
type SailingLink = {
  name: "cases-caseId-hits-sailings-sailingId";
  params: {
    caseId: string;
    sailingId: IdOrIndex;
  };
};

const plusOne = (id: IdOrIndex) => (typeof id === "number" ? id + 1 : id);
export function ctuRouteParams(
  caseId: string | null,
  ctuIndex: IdOrIndex,
  cargoIndex: IdOrIndex | null
): ContainerLink | CargoLink {
  if (cargoIndex === null) {
    return {
      name: "cases-caseId-hits-containers-ctuId",
      params: {
        caseId,
        ctuId: plusOne(ctuIndex),
      },
    } as ContainerLink satisfies RouteLocationAsRelativeGeneric;
  } else {
    return {
      name: "cases-caseId-hits-containers-ctuId-cargo-cargoId",
      params: {
        caseId,
        ctuId: plusOne(ctuIndex),
        cargoId: plusOne(cargoIndex),
      },
    } as CargoLink satisfies RouteLocationAsRelativeGeneric;
  }
}

export function getRouteParamsFromField(caseId: string, path: string) {
  const hitPath = parsePathFromMatch(path);

  if (hitPath === "containers") {
    const res = parseCtuAndMaybeCargoIndexFromField(path);
    if (res === null)
      return {
        name: "cases-caseId-hits-containers",
        params: { caseId },
      } as HitLink satisfies RouteLocationAsRelativeGeneric;
    return ctuRouteParams(caseId, res[0], res[1]);
  } else if (hitPath === "sailings") {
    const res = parseSailingIndexFromPath(path);
    if (res === null)
      return {
        name: "cases-caseId-hits-sailings",
      } as SailingPathLink satisfies RouteLocationAsRelativeGeneric;
    else
      return {
        name: "cases-caseId-hits-sailings-sailingId",
        params: { caseId, sailingId: res },
      } as SailingLink satisfies RouteLocationAsRelativeGeneric;
  } else {
    return {
      name: `cases-caseId-hits-${hitPath}`,
      params: { caseId },
    } as HitLink satisfies RouteLocationAsRelativeGeneric;
  }
}

export function getDisplayFieldAndRouteParams(
  caseId: string,
  field: string
): [IdOrIndex | null, RouteLocationAsRelativeGeneric] {
  const info = getFriendlyLocationInfoFromField(field);
  let id: IdOrIndex | null | undefined;
  if ((id = info[EntityType.CTU]) !== null) {
    const detail = info[EntityType.CargoDetail];
    const displayField = detail?.[0] ?? null;
    const cargoId = info[EntityType.Cargo];
    const link =
      id === undefined
        ? { name: "cases-caseId-hits-containers", params: { caseId } }
        : ctuRouteParams(caseId, id, cargoId ?? null);

    return [displayField, link];
  } else if (info[EntityType.Booking] !== null) {
    const displayField = String(info[EntityType.Booking]);

    return [
      displayField,
      { name: "cases-caseId-hits-booking", params: { caseId } },
    ];
  } else if ((id = info[EntityType.Sailing]) !== null) {
    let displayField =
      Array.from(field.matchAll(/stages\[\d+\]\.(.+?)(\.|$)/gi)).at(0)?.[1] ??
      null;
    if (!displayField) {
      displayField =
        Array.from(field.matchAll(/stages\[(\d)+\]/gi)).at(0)?.[1] ?? null;
      if (displayField) displayField = "stage " + (parseInt(displayField) + 1);
    }
    let link: any;
    if (id === undefined)
      link = { name: "cases-caseId-hits-sailings", params: { caseId } };
    else
      link = {
        name: "cases-caseId-hits-sailings-sailingId",
        params: { caseId, sailingId: id },
      };
    return [displayField, link];
  } else if (info[EntityType.Party] !== null) {
    const displayField = info[EntityType.Party] ?? null;
    return [
      displayField,
      { name: "cases-caseId-hits-parties", params: { caseId } },
    ];
  }
  return [null, { name: "cases-caseId-hits-booking", params: { caseId } }];
}

export function isPathContainer(path: string | Path): boolean {
  const builtPath = typeof path === "string" ? path : path.build();
  const indexes = parseCtuAndMaybeCargoIndexFromField(builtPath);
  return indexes !== null && indexes[1] === null;
}

export function isPathSailing(path: string | Path): boolean {
  const builtPath = typeof path === "string" ? path : path.build();
  const indexes = parseSailingAndMaybeStageIndexFromField(builtPath);
  return indexes !== null && indexes[1] === null;
}
