import type { ScreenResultViewModel } from "~/src/models/Case/Screen.model";
import type { Uris } from "~/types/api";

import { useNuxtApp, useQueryClient } from "#imports";
import { unref, type MaybeRef } from "vue";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { createQuery, type MyQueryOptions } from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

const endpoints = {
  get: parseUriTemplate("/api/hcd/screen-results/{id}"),
  dgPrediction: {
    list: parseUriTemplate("/api/hcd/screen-results/{id}/dg-predictions/list"),
  },
  anomaly: {
    list: parseUriTemplate("/api/hcd/screen-results/{id}/anomaly/list"),
  },
} as const satisfies Uris;

export const useScreeningService = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();

  const getScreenResult = async (screeningId: string, signal?: AbortSignal) => {
    // same stupid type error with SerialisedDate
    const res = await $api<ScreenResultViewModel>(
      endpoints.get.expand({ id: screeningId }),
      {
        signal,
        method: "GET",
      }
    );
    return res;
  };
  const useGetScreenResultQuery = (
    screeningId: MaybeRef<string | null>,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.ScreenResults.get, screeningId],
      ({ signal }) => {
        const id = unref(screeningId);
        if (!id) return null;
        return getScreenResult(id, signal);
      },
      {
        createNuxtError: true,
        suspense: true,
        ...options,
      }
    );

  const cacheScreenResults = (screeningResult: ScreenResultViewModel) => {
    queryClient.setQueryData(
      [QUERY_KEYS.ScreenResults.get, screeningResult.screeningId],
      screeningResult
    );
  };

  const listDgPredictions = async (
    screeningId: string,
    signal?: AbortSignal
  ) => {
    const result = await $api(
      endpoints.dgPrediction.list.expand({ id: screeningId }),
      {
        signal,
      }
    );
    return result ?? null;
  };

  const listDgPredictionsCached = (id: string) =>
    queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.DgPredictions.list, id],
      queryFn: async ({ signal }) => listDgPredictions(id, signal),
      retry: false,
      staleTime: 1000 * 60 * 3,
    });
  const useListDgPredictionsQuery = (
    screeningId: MaybeRef<string | null>,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.DgPredictions.list, screeningId],
      ({ signal }) => {
        const id = unref(screeningId);
        if (!id) return null;
        return listDgPredictions(id, signal);
      },
      {
        createNuxtError: true,
        suspense: true,
        ...options,
      }
    );

  const listAnomaly = async (screeningId: string, signal?: AbortSignal) => {
    const result = await $api(
      endpoints.anomaly.list.expand({ id: screeningId }),
      {
        signal,
      }
    );
    return result ?? null;
  };

  const listAnomalyCached = (id: string) =>
    queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.Anomaly.list, id],
      queryFn: ({ signal }) => listAnomaly(id, signal),
      retry: false,
      staleTime: 1000 * 60 * 3,
    });
  const useListAnomalyQuery = (
    screeningId: MaybeRef<string | null>,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.Anomaly.list, screeningId],
      ({ signal }) => {
        const id = unref(screeningId);
        if (!id) return null;
        return listAnomaly(id, signal);
      },
      {
        createNuxtError: true,
        suspense: true,
        ...options,
      }
    );

  return {
    getScreenResult,
    listDgPredictions,
    cacheScreenResults,
    listDgPredictionsCached,
    listAnomaly,
    listAnomalyCached,

    useGetScreenResultQuery,
    useListDgPredictionsQuery,
    useListAnomalyQuery,
  };
};
