import { defineNuxtPlugin, useRequestHeaders } from "#imports";

export default defineNuxtPlugin({
  setup() {
    // add cookies to all requests using this fetch instance

    const cookie = useRequestHeaders(["Cookie", "Origin", "Content-Type"]);
    const api = $fetch.create({
      headers: cookie,
    });

    return {
      provide: {
        api,
      },
    };
  },
});
