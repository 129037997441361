import { QUERY_KEYS as k } from "./queryKeys";

export type QueryInvalidationPayload = string[][];

const genericCRUD = (queryKeyObj: { list: string; get: string }) => {
  return {
    create: () => [[queryKeyObj.list]],
    delete: () => [[queryKeyObj.list]],
    update: (id: string) => [[queryKeyObj.list], [queryKeyObj.get, id]],
  };
};

type Invalidators = {
  [K: string]: ((...args: any[]) => QueryInvalidationPayload) | Invalidators;
};

const caseGet = (caseId: string) => [k.Cases.get, caseId];
const caseHistory = (caseId: string) => [k.Cases.history, caseId];
const caseAction = (caseId: string) => [
  caseGet(caseId),
  caseHistory(caseId),
  [k.Cases.list],
];
const caseBulkAction = (caseIds: string[]) => [
  ...caseIds.flatMap((caseId) => [caseGet(caseId), caseHistory(caseId)]),
  [k.Cases.list],
];

const inspectionShared = (caseId: string) => [
  caseGet(caseId),
  [k.Cases.InspectionRequests.list],
  [k.Cases.InspectionRequests.get, caseId],
];

export const InvalidationKeys = {
  Case: {
    setStatus: caseAction,
    assignUser: caseAction,
    bulkAssign: caseBulkAction,
    bulkStatus: caseBulkAction,
    addNote: (caseId: string) => [caseGet(caseId), caseHistory(caseId)],
    setDueDate: caseAction,
    sendEmail: (caseId: string) => [caseHistory(caseId)],

    InspectionRequest: {
      create: inspectionShared,
      delete: (id: string) => [caseGet(id), [k.Cases.InspectionRequests.list]],
      update: inspectionShared,

      Attachment: {
        delete: (id: string) => [
          [k.Cases.InspectionRequests.Attachments.list, id],
        ],
        create: (id: string) => [
          [k.Cases.InspectionRequests.Attachments.list, id],
        ],
      },
    },
  },
  EmailTemplate: genericCRUD(k.EmailTemplates),
  ServiceHook: genericCRUD(k.ServiceHook),
  Library: {
    ...genericCRUD(k.Libraries),
    Rule: {
      create: (libraryId: string) => [[k.Libraries.Rules.list, libraryId]],
      delete: (libraryId: string) => [[k.Libraries.Rules.list, libraryId]],
      update: (libraryId: string, id: string) => [
        [k.Libraries.Rules.list, libraryId],
        [k.Libraries.Rules.get, libraryId, id],
      ],
    },
    RuleTemplate: {
      create: (libraryId: string) => [
        [k.Libraries.RuleTemplates.list, libraryId],
      ],
      delete: (libraryId: string) => [
        [k.Libraries.RuleTemplates.list, libraryId],
      ],
      update: (libraryId: string, id: string) => [
        [k.Libraries.RuleTemplates.list, libraryId],
        [k.Libraries.RuleTemplates.get, libraryId, id],
      ],
      cascadeUpdate: (id: string, templateId: string) => [
        [k.Libraries.RuleTemplates.Operations.list, id, templateId],
      ],
    },
  },
  TemplateGroup: {
    ...genericCRUD(k.TemplateGroups),
    Template: {
      create: (templateGroupId: string) => [
        [k.TemplateGroups.templates.list, templateGroupId],
      ],
      delete: (templateGroupId: string) => [
        [k.TemplateGroups.templates.list, templateGroupId],
      ],
      update: (templateGroupId: string, id: string) => [
        [k.TemplateGroups.templates.list, templateGroupId],
        [k.TemplateGroups.templates.get, templateGroupId, id],
      ],
    },
  },
  Rule: genericCRUD(k.Libraries),
  DataBlock: {
    ...genericCRUD(k.DataBlocks),
    cascadeUpdate: {
      create: (dataBlockId: string) => [
        [k.DataBlocks.Operations.list, dataBlockId],
      ],
    },
  },
  Incident: genericCRUD(k.Incidents),
  Policy: genericCRUD(k.Policy),
  Surveyor: genericCRUD(k.Surveyors),
  TeamMember: {
    create: () => [[k.Team.members.list]],
    delete: () => [[k.Team.members.list]],
    update: () => [[k.Team.members.list]],
  },
  Configuration: {
    update: (configId: string) => {
      const result = [[k.Config.get, configId]];

      if (configId === "case-list")
        result.push(
          [k.CaseColumnsConfig.get],
          [k.CaseTableFilters.get],
          [k.CasePageConfig.get]
        );
      else if (configId === "status-metadata")
        result.push([k.StatusMetadata.get]);

      return result;
    },
  },
} as const satisfies Invalidators;
