<template>
  <ClientOnly>
    <q-avatar
      class="avatar"
      color="primary"
      :size="props.size ?? 'md'"
      text-color="white"
    >
      <span v-if="imgUrl === null">
        {{
          getInitialsFromFullName(typeof user === "string" ? user : user.name)
        }}
      </span>
      <q-img v-else no-transition :src="imgUrl" />
      <slot />
    </q-avatar>
  </ClientOnly>
</template>

<script setup lang="ts">
import { computed } from "vue";
import md5 from "md5";
import type { UserInformation } from "~/src/models/User.model";
import { getInitialsFromFullName } from "~/utils/helpers";

const props = defineProps<{
  user: Omit<UserInformation, "id" | "isHidden"> | string;
  size?: string;
}>();

type AvatarType =
  | "404"
  | "mp"
  | "identicon"
  | "monsterid"
  | "wavatar"
  | "retro"
  | "robohash"
  | "blank";
type GravatarUrl<T extends string> =
  `https://www.gravatar.com/avatar/${T}?d=${AvatarType}`;

const gravatarURL = (email: string): GravatarUrl<string> => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};
const imgUrl = computed(() => {
  const user = props.user;
  if (typeof user === "string") return gravatarURL(user);
  return user.email == null ? null : gravatarURL(user.email);
});
</script>
