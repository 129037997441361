import { useSocket } from "~/composables/useSocket.client";
import { useQueryClient } from "@tanstack/vue-query";
import { invalidateQueries } from "~/utils/queryUtils";
import { useUserStore } from "~/stores/userStore";

// Handle PUT/POST/DELETE ?
export const useSocketInvalidation = () => {
  const queryClient = useQueryClient();
  const { socket } = useSocket();
  const { user, isLoggedIn } = useUserStore();

  socket.on("entity:updated", (args, userId) => {
    if (!isLoggedIn || !userId) return;

    // This is prevents double call, invalidation is called on the client
    if (userId === user?.id) return;
    invalidateQueries(args, queryClient);
  });
};
