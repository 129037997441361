import { navigateTo } from "#imports";
import { useOurNuxtApp } from "~/utils/nuxt";
import { useQueryClient } from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import useNotify from "~/composables/useNotify";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  invalidateQueries,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { getPayload } from "./EmailService";
import { InvalidationKeys } from "~/utils/queryInvalidators";

import type { MyQueryOptions } from "~/utils/queryUtils";
import type { BackendBookingViewModel } from "../models/Case/Booking.viewmodel";
import type { ScreenResultViewModel } from "../models/Case/Screen.model";
import type { Email } from "../models/Email/Email.model";
import type { EmailTemplate } from "../models/Email/EmailTemplate.model";
import type { RequestObject } from "../models/utils/Api.model";
import type { ListEmailTemplatesQuery } from "~/server/api/cm/email-templates/index.get";

const endpoints = {
  get: parseUriTemplate("/api/cm/email-templates/{id}"),
  list: "/api/cm/email-templates",
  create: "/api/cm/email-templates",
  update: parseUriTemplate("/api/cm/email-templates/{id}"),
  delete: parseUriTemplate("/api/cm/email-templates/{id}"),
  download: parseUriTemplate("/api/cm/case/${id}/emails/download"),
} as const;

const getMailList = (payload: EmailTemplate) => {
  return {
    ...payload,
    mailList: payload.mailList.map((email) => {
      if (email) return { address: email };
    }),
  };
};

export const useEmailTemplateService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  const getEmailTemplate = (id: string, signal?: AbortSignal) => {
    if (id === "new") return;
    return $api(endpoints.get.expand({ id }), { signal });
  };

  const useGetEmailTemplateQuery = (id: string, options?: MyQueryOptions) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.get, id],
      ({ signal }) => getEmailTemplate(id, signal),
      options
    );

  const listEmailTemplates = (req: RequestObject, signal?: AbortSignal) =>
    $api(endpoints.list, {
      query: req satisfies ListEmailTemplatesQuery,
      signal,
    });

  const useListEmailTemplatesQuery = (
    req: MaybeRef<RequestObject | null>,
    opt?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.list],
      ({ signal }) => {
        const _req = unref(req);
        if (_req === null) return null;
        return listEmailTemplates(_req, signal);
      },
      opt
    );

  const createEmailTemplate = (payload: EmailTemplate) =>
    $api(endpoints.create, {
      method: "POST",
      body: getMailList(payload),
    });

  const useCreateEmailTemplateMutation = () =>
    createMutation((template: EmailTemplate) => createEmailTemplate(template), {
      onError: () => notifyError(t("error.creating_email_template")),
      onSuccess: () => {
        invalidateQueries(InvalidationKeys.EmailTemplate.create(), queryClient);
        notifySuccess(
          t("success.success"),
          t("success.email_template_created")
        );
        navigateTo("/email-templates");
      },
    });

  const updateEmailTemplate = (id: string, payload: EmailTemplate) =>
    $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body: getMailList(payload),
    });

  const useUpdateEmailTemplateMutation = () => {
    type UpdateEmailTemplatePayload = {
      id: string;
      values: EmailTemplate;
    };
    return createMutation(
      ({ id, values }: UpdateEmailTemplatePayload) =>
        updateEmailTemplate(id, values),
      {
        onSuccess: (x, { id }) => {
          invalidateQueries(
            InvalidationKeys.EmailTemplate.update(id),
            queryClient
          );
          notifySuccess(
            t("success.success"),
            t("success.email_template_updated")
          );
        },
        onError: () => {
          return notifyError(t("error.updating_email_template"));
        },
      }
    );
  };

  // proxy endpoint
  const deleteEmailTemplate = (id: string) =>
    $api<undefined>(endpoints.delete.expand({ id }), {
      method: "DELETE",
    });

  const useDeleteEmailTemplateMutation = () =>
    createMutation((id: string) => deleteEmailTemplate(id), {
      onSuccess: () => {
        invalidateQueries(InvalidationKeys.EmailTemplate.delete(), queryClient);

        notifySuccess(
          t("success.success"),
          t("success.email_Template_deleted")
        );
      },
      onError: () => notifyError(t("error.deleting_email_template")),
    });

  const exportCaseEmail = (
    id: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) =>
    $api(endpoints.download.expand({ id }), {
      method: "POST",
      body: getPayload({
        recipients: payload.recipients,
        message: payload.message,
        emailTemplate: payload.emailTemplate,
        screenResult: screenResult,
        booking: booking,
      }),
    });

  const useExportCaseEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => exportCaseEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError(t("error.creating_email")),
        onSuccess: () =>
          notifySuccess(t("success.success"), t("success.email_created")),
      }
    );

  return {
    useListEmailTemplatesQuery,
    useGetEmailTemplateQuery,
    getEmailTemplate,
    createEmailTemplate,
    listEmailTemplates,
    useUpdateEmailTemplateMutation,
    useCreateEmailTemplateMutation,
    useDeleteEmailTemplateMutation,
    useExportCaseEmailMutation,
  };
};
