import { ref, onMounted, onBeforeUnmount } from "vue";
import { io } from "socket.io-client";

const socket = io({
  autoConnect: false,
  transports: ["websocket", "polling"],
});

let interval: NodeJS.Timeout | null = null;

export const useSocket = () => {
  const isConnected = ref(false);
  const transport = ref("N/A");
  const error = ref<string | null>(null);

  function onConnect() {
    console.log("Socket connected");
    isConnected.value = true;
    error.value = null;
    transport.value = socket.io.engine.transport.name;

    socket.io.engine.on("upgrade", (rawTransport) => {
      transport.value = rawTransport.name;
    });
  }

  function onDisconnect(reason: string) {
    isConnected.value = false;
    transport.value = "N/A";
    error.value = reason;
  }

  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnect);

  socket.on("disconnect", (reason, details) => {
    console.log(reason);
    console.log((details as any)?.message);
    console.log((details as any)?.description);
    console.log((details as any)?.context);
  });

  socket.on("connect_error", (err) => {
    console.log(err.message);
    console.log((err as any).description);
    console.log((err as any).context);
  });

  const updateValues = () => {
    isConnected.value = socket.connected;
    transport.value = socket.io?.engine?.transport?.name ?? "N/A";
  };

  onMounted(() => {
    updateValues();

    if (!interval) {
      interval = setInterval(updateValues, 2000);
    }
    if (isConnected.value) return;
    socket.connect();
  });

  onBeforeUnmount(() => {
    socket.off("connect", onConnect);
    socket.off("disconnect", onDisconnect);
    if (interval !== null) clearInterval(interval);
  });

  return {
    isConnected,
    transport,
    error,
    socket,
  };
};
