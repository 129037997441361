import { useCaseFilters } from "~/composables/useCaseFilters";
import { useUserStore } from "~/stores/userStore";
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";
import { debouncedWatch, useState, _IsEqual } from "#imports";
import { useUserService } from "~/src/services/UserService";

import type { RailLink } from "~/components/Layout/LayoutRailLink.vue";

export const useRailLinks = async () => {
  const { t } = useI18n({ useScope: "global" });
  const store = useUserStore();
  const { resetRequest } = useCaseFilters();
  const { useRailLinksQuery, useRailLinksMutation } = useUserService();

  const { mutate: updateRailLinksMutation } = useRailLinksMutation();

  const moreOptionsMenuOpen = useState("moreOptionsMenuOpen", () => false);

  const getLinksList = (): RailLink[] => [
    {
      id: "cases",
      title: t("feature.cases"),
      icon: "mdi-clipboard-check-multiple",
      linkName: "/cases",
      callback: () => resetRequest(),
      canGoTo: () => store.isLoggedIn && store.hasPermission.Case.read,
    },
    {
      id: "email_templates",
      title: t("feature.email_templates"),
      icon: "mdi-email",
      linkName: "/email-templates",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Emailtemplate.read,
      isHardlyUsed: true,
    },
    {
      id: "policies",
      title: "Policies",
      icon: "mdi-ballot",
      linkName: "/policies",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Policy.read,
      isHardlyUsed: true,
    },
    {
      id: "libraries",
      title: "Libraries",
      icon: "mdi-book-open",
      linkName: "/libraries",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Library.read,
    },
    {
      id: "service_hooks",
      title: "Service Hooks",
      icon: "mdi-hook",
      linkName: "/service-hooks",
      canGoTo: () =>
        store.isLoggedIn && store.hasPermission["Service-hook"].read,
      isHardlyUsed: true,
    },
    {
      id: "incidents",
      title: "Incidents",
      icon: "mdi-exit-run",
      linkName: "/incidents",
      canGoTo: () => store.isLoggedIn && store.hasPermission.incident.read,
      isHardlyUsed: true,
    },
    {
      id: "template_groups",
      title: "Template Groups",
      icon: "mdi-vector-triangle",
      linkName: "/template-groups",
      canGoTo: () => store.isLoggedIn && store.hasPermission["Template"].read,
      isHardlyUsed: true,
    },
    {
      id: "data_blocks",
      title: "Data Blocks",
      icon: "mdi-data-matrix",
      linkName: "/data-blocks",
      canGoTo: () => store.isLoggedIn && store.hasPermission["Template"].read,
      isHardlyUsed: true,
    },
    {
      id: "inspections",
      title: "Inspections",
      icon: "mdi-camera-document",
      linkName: "/inspections/surveyors",
      canGoTo: () =>
        store.isLoggedIn &&
        store.hasPermission["containerinspectioncompany"].read,
    },
    {
      id: "verified_shippers",
      title: t("feature.verified_shippers"),
      icon: "mdi-ferry",
      linkName: "/verified-shippers",
      canGoTo: () =>
        store.isLoggedIn && store.hasPermission["globalverifiedshipper"].search,
    },
    {
      id: "analytics",
      title: t("feature.analytics"),
      icon: "mdi-chart-box-outline",
      children: [
        {
          id: "analytics_global",
          title: t("analytics.global"),
          icon: "mdi-earth",
          linkName: "/analytics/global",
        },
        {
          id: "analytics_incidents",
          title: t("analytics.incidents"),
          icon: "mdi-exit-run",
          linkName: "/analytics/incidents",
        },
      ],
      canGoTo: () => store.isLoggedIn && store.hasPermission.Analytics.read,
    },
    {
      id: "user_guide",
      title: t("general.user_guide"),
      icon: "mdi-book-open-page-variant",
      linkName:
        "https://docs.hazcheck.com/external/manual/hazcheck-detect-cargo-safety-program/article/title-page?p=2d9095e223520abfd53a7f8c02e872df2dc61f3a6cc5177a5958e98df3e9d931adc0434203e7c69bc9500f10efd9f4c5",
      externalLink: true,
      canGoTo: () => store.isLoggedIn,
    },
  ];

  const allVisibleLinks = computed(() => {
    return getLinksList().filter((link) => {
      if (link.canGoTo) return link.canGoTo();
      return true;
    });
  });

  const defaultLinks = allVisibleLinks.value.filter(
    (x) => x.isHardlyUsed === undefined || x.isHardlyUsed === false
  );

  const defaultExtraLinks = allVisibleLinks.value.filter(
    (x) => x.isHardlyUsed === true
  );

  const sideBarLinks = ref<RailLink[]>([]);
  const extraLinks = ref<RailLink[]>([]);

  const queryEnabled = computed(() => store.isLoggedIn);

  const { data } = await useRailLinksQuery({
    enabled: queryEnabled,
    keepPreviousData: true,
  });

  const updateRailLinks = (links: string[] | null | undefined) => {
    if (!links) {
      sideBarLinks.value = defaultLinks;
      extraLinks.value = defaultExtraLinks;
      return;
    }
    sideBarLinks.value = [];
    extraLinks.value = [];
    for (const link of links) {
      const found = allVisibleLinks.value.find((x) => x.id === link);
      if (found) sideBarLinks.value.push(found);
    }
    extraLinks.value = allVisibleLinks.value.filter(
      (x) => !sideBarLinks.value.some((y) => y.id === x.id)
    );
  };

  watch(data, (val, oldVal) => {
    if (_IsEqual(val, oldVal)) return;
    updateRailLinks(val);
  });

  updateRailLinks(data.value);

  debouncedWatch(
    sideBarLinks,
    (val) => {
      updateRailLinksMutation({ links: val.map((x) => x.id) });
    },
    { debounce: 500 }
  );

  return {
    allVisibleLinks,
    sideBarLinks,
    extraLinks,
    moreOptionsMenuOpen,
  };
};
