import { createError } from "#imports";
import type {
  BookingCargoViewModel,
  BookingContainerViewModel,
  BookingViewModel,
} from "./Booking.viewmodel";
import { Probability } from "~/server/utils/issues";

// backo
// corresponds to AbnormalBehaviorModel

export type CargoTransportUnitViewModel = {
  ctuId: string;
  cargo: CargoViewModel[];
};

export type CargoViewModel = {
  cargoId: string;
  results: CargoResultViewModel[];
};

export type CargoResultViewModel = {
  label: string;
  value: string;
  results: ResultViewModel[];
  path: string;
};

export type ResultViewModel = {
  description: string;
  origin: string;
  destination: string;
};

export type AnomalyViewModel = {
  reference: string;
  screeningId: string;
  sailings: ResultViewModel[];
  cargoTransportUnits: CargoTransportUnitViewModel[];
};

// frono

export type CargoTransportUnitSummary = {
  name: string;
  ctuId: string;

  cargo: CargoSummary[];

  path: string;
  index: number;

  maxProbability: Probability;
  maxCount: number;
  totalCount: number;
};

export type CargoSummary = {
  name: string;
  cargoId: string;

  results: CargoResultSummary[];

  path: string;
  index: number;

  maxProbability: Probability;
  maxCount: number;
  totalCount: number;
};

export type CargoResultSummary = {
  label: string;
  value: string;
  results: ResultViewModel[];

  path: string;

  maxProbability: Probability;
  maxCount: number;
  totalCount: number;
};

export type AnomalySummary = {
  reference: string;
  screeningId: string;
  sailings: ResultViewModel[];
  cargoTransportUnits: CargoTransportUnitSummary[];

  maxProbability: Probability;
  maxCount: number;
  totalCount: number;
};

export function probabilityToNumber(description: Probability): number {
  if (description === Probability.Unlikely) return 0.2;
  if (description === Probability.Possible) return 0.5;
  if (description === Probability.Likely) return 0.75;
  if (description === Probability.VeryLikely) return 0.9;
  return 0;
}
export function descriptionToNumber(description: string): number {
  if (description === "common") return 0.2;
  if (description === "uncommon") return 0.5;
  if (description === "rare") return 0.75;
  return 0;
}

export function groupProbability(description: string): Probability {
  if (description === "common") return Probability.Unlikely;
  if (description === "uncommon") return Probability.Possible;
  if (description === "rare") return Probability.Likely;
  return Probability.Likely;
}
export const minimumProbability = Probability.Possible;

export function getResultColour(description: string): string {
  if (description === "common") return "green";
  if (description === "uncommon") return "yellow";
  if (description === "rare") return "red";
  return "green";
}

export function getAnomalyProbabilityTag(probability: number): string {
  if (probability === Probability.Unlikely)
    return "case.issues.probability.common";
  if (probability === Probability.Likely) return "case.issues.probability.rare";
  return "case.issues.probability.rare";
}

function getMaxProb(
  arr: ({ maxCount?: number } & (
    | { description: string }
    | { maxProbability: Probability }
  ))[]
): [Probability, number] {
  let maxProbGroup = Probability.Unlikely;
  let count = 0;

  for (const el of arr) {
    const probGroup =
      "maxProbability" in el
        ? el.maxProbability
        : groupProbability(el.description);
    if (probGroup > maxProbGroup) {
      count = el.maxCount ?? 1;
      maxProbGroup = probGroup;
      continue;
    } else if (probGroup === maxProbGroup) {
      count += el.maxCount ?? 1;
      continue;
    }
  }
  return [maxProbGroup, count];
}

export function getCargoResultSummary(
  cargo: CargoResultViewModel
): CargoResultSummary {
  const [maxProb, count] = getMaxProb(cargo.results);
  return {
    ...cargo,
    maxProbability: maxProb,
    maxCount: count,
    totalCount: cargo.results.reduce((prev, curr) => {
      if (groupProbability(curr.description) >= minimumProbability)
        return prev + 1;
      return prev;
    }, 0),
  };
}

export function getCargoSummary(
  cargo: CargoViewModel,
  cargos: BookingCargoViewModel[]
): CargoSummary {
  const index = parseInt(cargo.cargoId);
  if (index === null)
    throw createError({ message: "error parsing cargo index from path" });

  const bookingCargo = cargos.at(index);

  // const singleResult = cargo.results[0];

  // TODO  - remove this when cargo paths are all fixed
  // cheers Daz
  function buggedResult(path: string) {
    return !path || path === "" || path === "None" || !path.startsWith("$.");
  }
  let singleResult = cargo.results.find((res) => !buggedResult(res.path));
  if (!singleResult) {
    const lessBuggedResult = cargo.results.find((res) =>
      res.path.startsWith("cargo-transport-units")
    );
    if (lessBuggedResult === undefined) {
      throw createError("all paths are bugged");
    }

    singleResult = {
      ...lessBuggedResult,
      path: "$." + lessBuggedResult.path,
    };
  }
  // ---

  const path = singleResult.path.substring(
    0,
    singleResult.path.indexOf("]", singleResult.path.indexOf(".cargo[")) + 1
  );

  // TODO - remove this when cargo paths are all fixed
  const fixedResults = cargo.results.map((result) => {
    if (!buggedResult(result.path)) return result;
    let newPath: string;
    if (result.label === "Schema") newPath = `${path}.$schema`;
    else newPath = `${path}.${result.label}`;
    return {
      ...result,
      path: newPath,
    };
  });
  const cargoResults = fixedResults.map(getCargoResultSummary);
  // ---

  // const cargoResults = cargo.results.map(getCargoResultSummary);

  const totalCount = cargoResults.reduce(
    (prev, curr) => prev + curr.totalCount,
    0
  );

  const [maxProb, count] = getMaxProb(cargoResults);

  if (!bookingCargo)
    throw createError({
      message: `there was a failure querying the booking with path ${path}`,
    });

  return {
    ...cargo,
    path,
    results: cargoResults,
    maxProbability: maxProb,
    maxCount: count,
    totalCount,
    index,
    name: bookingCargo.displayName,
  };
}

export function getCtuSummary(
  ctu: CargoTransportUnitViewModel,
  containers: BookingContainerViewModel[]
): CargoTransportUnitSummary {
  if (ctu.cargo.length === 0)
    throw createError({ message: "ctu must have non-zero amount of cargo" });
  const index = parseInt(ctu.ctuId);

  const container = containers[index];
  const cargoSummaries = ctu.cargo.map((cargo) =>
    getCargoSummary(cargo, container.cargo)
  );

  const [maxProb, count] = getMaxProb(cargoSummaries);
  const totalCount = cargoSummaries.reduce(
    (prev, curr) => prev + curr.totalCount,
    0
  );

  const path = `$.cargo-transport-units[${index}]`;
  return {
    ...ctu,
    maxProbability: maxProb,
    maxCount: count,
    totalCount,
    index,
    path,
    cargo: cargoSummaries,
    name: container.displayName,
  };
}

export function getAnomalySummary(
  anomaly: AnomalyViewModel,
  booking: BookingViewModel
): AnomalySummary | null {
  const ctuSummaries = anomaly.cargoTransportUnits.map((ctu) =>
    getCtuSummary(ctu, booking.containers)
  );

  const [maxProb, count] = getMaxProb(ctuSummaries);
  const totalCount = ctuSummaries.reduce(
    (prev, curr) => prev + curr.totalCount,
    0
  );

  if (totalCount === 0) return null;

  const [maxProbSailing, countSailing] = getMaxProb(anomaly.sailings);

  const [combinedMaxProb, combinedMaxCount] = (() => {
    if (maxProb === maxProbSailing) return [maxProb, count + countSailing];
    if (maxProb === Probability.Likely) return [maxProb, count];
    if (maxProbSailing === Probability.Likely)
      return [maxProbSailing, countSailing];
    return [Probability.Unlikely, 0];
  })();

  return {
    ...anomaly,
    maxProbability: combinedMaxProb,
    maxCount: combinedMaxCount,
    totalCount: totalCount + anomaly.sailings.length,
    cargoTransportUnits: ctuSummaries,
  };
}
