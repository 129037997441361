import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import useNotify from "~/composables/useNotify";
import type { RouteRecordName } from "vue-router";

export default function useGoBack() {
  const route = useRoute();
  const router = useRouter();
  const { notifyError } = useNotify();
  const { t } = useI18n({ useScope: "global" });

  const routeMappings = new Map<RouteRecordName, string>([
    ["cases-caseId", "/cases"],
    ["email-templates-id", "/email-templates"],
    ["libraries-id", "/libraries"],
    ["libraries-id-rules-ruleId", `/libraries/${route.params.id}`],
    ["libraries-id-template-templateId", `/libraries/${route.params.id}`],
    ["policies-id", "/policies"],
    ["service-hooks-id", "/service-hooks"],
    ["template-groups-id", "/template-groups"],
    ["template-groups-id-templateId", `/template-groups/${route.params.id}`],
    ["data-blocks-id", "/data-blocks"],
    ["incidents-id", "/incidents"],
  ]);

  const backExists = computed(() => router.options.history.state.back);
  const routeName = computed(() => router.currentRoute.value.name);

  const errorRouteName = computed(() => {
    const rootPage = String(router.currentRoute.value.path).split("/")[1];
    if (rootPage) return `/${rootPage}`;
    else return null;
  });

  const backRouteFallback = computed(() => {
    if (routeName.value) return routeMappings.get(routeName.value);
  });

  const goBack = () => {
    if (backExists.value) router.back();
    else if (backRouteFallback.value) router.push(backRouteFallback.value);
    // If on error page
    else if (errorRouteName.value) router.push(errorRouteName.value);
    else notifyError(t("error.performing_navigation"));
  };

  return goBack;
}
