import { useOurNuxtApp } from "~/utils/nuxt";
import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import {
  deserialiseIncident,
  deserialisePagedIncident,
  type FrontendIncidentCreateModel,
} from "~/src/models/Incidents/Incident.model";
import {
  DefaultServiceBuilder,
  createMutation,
  invalidateQueries,
} from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { getErrorMessagesFromError } from "~/utils/helpers";
import { InvalidationKeys } from "~/utils/queryInvalidators";

const endpoints = {
  create: "/api/cm/incidents",
  list: "/api/cm/incidents/list",
  get: parseUriTemplate("/api/cm/incidents/{id}"),
  update: parseUriTemplate("/api/cm/incidents/{id}"),
  delete: parseUriTemplate("/api/cm/incidents/{id}"),
} as const;

export const useIncidentService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const client = useQueryClient();
  const notifier = useNotify();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = notifier;

  const builder = new DefaultServiceBuilder(
    "Incident",
    $api,
    QUERY_KEYS.Incidents,
    client,
    notifier,
    t
  );

  const updateIncident = (id: string, body: FrontendIncidentCreateModel) =>
    $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body,
    });
  const useUpdateIncidentMutation = () =>
    createMutation(
      ({ id, body }: { id: string; body: FrontendIncidentCreateModel }) =>
        updateIncident(id, body),
      {
        onSuccess: (_, { id }) => {
          invalidateQueries(InvalidationKeys.Incident.update(id), queryClient);
          notifySuccess(t("general.success"), t("incidents.updated"));
        },
        onError: (e) => notifyError(getErrorMessagesFromError(e).join("\n")),
      }
    );

  const deleteIncident = (id: string) =>
    $api(endpoints.delete.expand({ id }), { method: "DELETE" });

  const useDeleteIncidentMutation = () =>
    createMutation((id: string) => deleteIncident(id), {
      onSuccess: (_x) => {
        invalidateQueries(InvalidationKeys.Incident.delete(), queryClient);
        notifySuccess(t("general.success"), t("incidents.deleted"));
      },
      onError: (e) => notifyError(getErrorMessagesFromError(e).join("\n")),
    });

  return {
    ...builder.useDefaultCreateMutation(endpoints.create, {
      body: (body: FrontendIncidentCreateModel) => body,
      navigateTo: "/incidents",
      // @ts-expect-error Serialise does weird stuff with SerialisedDate
      deserialise: deserialiseIncident,
    }),
    ...builder.useDefaultListQuery(endpoints.list, {
      deserialise: deserialisePagedIncident,
    }),
    ...builder.useDefaultGetQuery(endpoints.get, {
      // @ts-expect-error Serialise does weird stuff with SerialisedDate
      deserialise: deserialiseIncident,
    }),
    useUpdateIncidentMutation,
    useDeleteIncidentMutation,
  };
};
