export const QUERY_KEYS = {
  ScreenResults: {
    get: "screenResults.get",
  },
  DgPredictions: { list: "dgPredictions.list" },
  Anomaly: { list: "anomaly.list" },
  Cases: {
    list: "cases.list",
    paging: "cases.paging",
    get: "cases.get",
    history: "cases.history.get",
    assignedTo: "cases.assignedTo.get",
    filterConfig: "cases.filterConfig.get",

    InspectionRequests: {
      post: "cases.inspectionRequests.post",
      list: "cases.inspectionRequests.list",
      get: "cases.inspectionRequests.get",

      Attachments: {
        get: "cases.inspectionRequests.attachments.get",
        list: "cases.inspectionRequests.attachments.list",
      },
    },
  },
  Surveyors: {
    list: "surveyors.list",
    get: "surveyors.get",
  },
  Screening: {
    get: "screening.get",
  },

  Users: {
    list: "users.list",
    get: "users.get",
    Rail: {
      get: "users.rail.get",
    },
  },
  Risks: {
    get: "risks.get",
  },
  EmailTemplates: {
    list: "emailTemplates.list",
    get: "emailTemplates.get",
  },
  Incidents: {
    list: "incidents.list",
    get: "incidents.get",
  },
  Ports: {
    list: "ports.list",
    get: "ports.get",
  },
  Countries: {
    list: "locations.list",
  },
  Locations: {
    list: "locations.list",
  },
  Policy: {
    list: "policy.list",
    get: "policy.get",
    getEmmbedded: "policy.get.embedded",
  },
  ServiceHook: {
    list: "serviceHook.list",
    get: "serviceHook.get",
  },
  Libraries: {
    list: "libraries.list",
    get: "libraries.get",
    Rules: {
      list: "libraries.rules.list",
      get: "libraries.rules.get",
    },
    RuleTemplates: {
      list: "libraries.ruleTemplates.list",
      get: "libraries.ruleTemplates.get",

      // TODO - this should really be under templateGroup -> templates -> operations
      Operations: {
        list: "libraries.ruleTemplates.operations.list",
      },
    },
    Keyword: { list: "libraries.keyword.list" },
  },
  TemplateGroups: {
    list: "templateGroups.list",
    get: "templateGroups.get",

    templates: {
      list: "templateGroups.templates.list",
      get: "templateGroups.templates.get",
    },
  },

  DataBlocks: {
    list: "dataBlocks.list",
    get: "dataBlocks.get",
    Operations: {
      list: "dataBlocks.operations.list",
      get: "dataBlocks.operations.get",
    },
  },

  VerifiedShipper: {
    search: "verifiedShippers.search",
  },

  // Config related
  StatusMetadata: {
    get: "statusMetadata.get",
  },
  CaseColumnsConfig: {
    get: "caseColumnsConfig.get",
  },
  CaseTableFilters: {
    get: "caseTableFilters.get",
  },
  CasePageConfig: {
    get: "casePageConfig.get",
  },
  Config: {
    get: "config.get",
    caseListValidate: "caseTable.validate",
    default: {
      get: "config.default.get",
    },
  },

  Team: {
    members: {
      list: "team.members.list",
    },
  },

  Analytics: {
    zoho: {
      get: "zoho.get",
    },
  },
} as const;
